export const home = [
    {
        name: "DRA",
        cardBg: "./Images/DRA-bg.webp",
        cardBorder: "solid 5px #241f1f",
        cardBoxShadow: "0 0 15px 3px #241f1f",
        dateColor: "#ffffff",
        date: "04",
        buttonBgColor: "#241f1f",
        textColor: "#ffffff",
        rulebookPath: "./RuleBooks/IDRL RuleBook.pdf",
    },
    {
        name: "ROBOWARS",
        cardBg: "./Images/Robowars.webp",
        cardBorder: "solid 5px #e37430",
        cardBoxShadow: "0 0 15px 3px #e37430",
        dateColor: "#ffffff",
        date: "04",
        buttonBgColor: "#e37430",
        textColor: "#ffffff",
        rulebookPath: "./RuleBooks/RoboWars RuleBook.pdf",
    },
    {
        name: "BRAINWIZ",
        cardBg: "./Images/Brainwiz.webp",
        cardBorder: "solid 5px #213555",
        cardBoxShadow: "0 0 15px 3px #213555",
        dateColor: "#213555",
        date: "04",
        buttonBgColor: "#213555",
        textColor: "#ffffff",
        rulebookPath: "./RuleBooks/BrainWiz RuleBook.pdf",
    },
    {
        name: "YOUTH TECH-TIC",
        cardBg: "./Images/YTT-bg.webp",
        cardBorder: "solid 5px #2B4A59",
        cardBoxShadow: "0 0 15px 3px #2B4A59",
        dateColor: "#2B4A59",
        date: "04",
        buttonBgColor: "#2B4A59",
        textColor: "#ffffff",
        rulebookPath: "./RuleBooks/Youth Tech-Tic RuleBook.pdf",
    },
    {
        name: "GAMEJAM",
        cardBg: "./Images/GameJam-bg.webp",
        cardBorder: "solid 5px #190051",
        cardBoxShadow: "0 0 15px 3px #190051",
        dateColor: "#190051",
        date: "04",
        buttonBgColor: "#190051",
        textColor: "#ffffff",
        rulebookPath: "./RuleBooks/GameJam RuleBook.pdf",
    },
    {
        name: "DRIFT RACING",
        cardBg: "./Images/Drift-Racing-bg.webp",
        cardBorder: "solid 5px #e37430",
        cardBoxShadow: "0 0 15px 3px #e37430",
        dateColor: "#ffffff",
        date: "04",
        buttonBgColor: "#e37430",
        textColor: "#ffffff",
        rulebookPath: "./RuleBooks/Drift RuleBook.pdf",
    },
    {
        name: "D'CODE",
        cardBg: "./Images/Dcode-bg.webp",
        cardBorder: "solid 5px #42e8e0",
        cardBoxShadow: "0 0 15px 3px #42e8e0",
        dateColor: "#ffffff",
        date: "04",
        buttonBgColor: "#42e8e0",
        textColor: "#ffffff",
        rulebookPath: "./RuleBooks/Dcode RuleBook.pdf",
    },
    {
        name: "STOCKRUSH",
        cardBg: "./Images/StockRush-bg.webp",
        cardBorder: "solid 5px #e0aa0d",
        cardBoxShadow: "0 0 15px 3px #e0aa0d",
        dateColor: "#ffffff",
        date: "01",
        buttonBgColor: "#e0aa0d",
        textColor: "#ffffff",
        rulebookPath: "./RuleBooks/StockRush RuleBook.pdf",
    },
    // {
    //     name: "INNOVATION NEXUS",
    //     cardBg: "./Images/IN-bg.webp",
    //     cardBorder: "solid 5px #005d88",
    //     cardBoxShadow: "0 0 15px 3px #005d88",
    //     dateColor: "#ffffff",
    //     date: "05",
    //     buttonBgColor: "#005d88",
    //     textColor: "#ffffff",
    //     rulebookPath: "./RuleBooks/StockRush RuleBook.pdf",
    // },
    // {
    //     name: "COMING SOON",
    //     cardBg: "./Images/coming_soon_mobile.webp",
    //     cardBorder: "solid 5px #d321a3",
    //     cardBoxShadow: "0 0 15px 3px #d321a3",
    //     dateColor: "#ffffff",
    //     date: "xx",
    //     buttonBgColor: "#d321a3",
    //     textColor: "#ffffff"
    // },
    // {
    //     name: "COMING SOON",
    //     cardBg: "./Images/coming_soon_mobile.webp",
    //     cardBorder: "solid 5px #d321a3",
    //     cardBoxShadow: "0 0 15px 3px #d321a3",
    //     dateColor: "#ffffff",
    //     date: "xx",
    //     buttonBgColor: "#d321a3",
    //     textColor: "#ffffff"
    // },
];