/*
    imgSrc,
    name,
    designation,
    linkedIn,
    insta,
    github,
    contact number,
    email id
*/

export const CoreData = [
  {
    name: "Harsh Jain",
    imgSrc: "./Images/harsh.webp",
    designation: "Events & Event Management Core",
    email: "harsh.jain@iitgn.ac.in",
    number: "+91 98814 67068",
    linkedin: "https://www.linkedin.com/in/harsh-jain-449a19255/",
    insta: "https://instagram.com/_harsh_jain_11",
  },
  {
    name: "Debojit Das",
    imgSrc: "./Images/debojit.webp",
    designation: "Sponsorship & Conclave & Symposium Core",
    email: "debojit.das@iitgn.ac.in",
    number: "+91 96090 18007",
    linkedin: "https://www.linkedin.com/in/debojitdas842/",
    insta: "https://instagram.com/the_quirky_munda",
  },
  {
    name: "Guntas Singh Saran",
    imgSrc: "./Images/guntas.webp",
    designation: "Design & Finance Core",
    email: "guntassingh.saran@iitgn.ac.in",
    number: "+91 73409 64064",
    linkedin: "https://www.linkedin.com/in/guntas-singh-saran-2b8811179/",
    insta: "https://www.instagram.com/guntas.saran13/",
  },
  {
    name: "Agastya Narayana",
    imgSrc: "./Images/agastya.webp",
    designation: "Tech Expo & Marketing Core",
    email: "mangalampalli.narayana@iitgn.ac.in",
    number: "+91 99201 32149",
    linkedin: "https://www.linkedin.com/in/agastya-narayana/",
    insta: "https://www.instagram.com/augustyeah",
  },
];

export const CoordData = [
  {
    name: "Suteekshna Mishra",
    imgSrc: "./Images/suteekshna.webp",
    designation: "Events Coordinator",
    email: "suteekshna.mishra@iitgn.ac.in",
    number: "+91 90444 12185",
    linkedin: "https://www.linkedin.com/in/suteekshna-mishra-14b15b253/",
    insta: "https://www.instagram.com/suteekshnam/",
  },
  {
    name: "Farhan Obaid",
    imgSrc: "./Images/farhan.webp",
    designation: "Event Management Coordinator",
    email: "farhan.obaid@iitgn.ac.in",
    number: "+91 76673 36359",
    linkedin: "https://www.linkedin.com/in/farhan-obaid-19b289262/",
    insta: "https://instagram.com/_farhanobaid_",
  },
  {
    name: "Hrriday Ruparel",
    imgSrc: "./Images/hrriday.webp",
    designation: "Sponsorship Coordinator",
    email: "hrriday.ruparel@iitgn.ac.in",
    number: "+91 90740 94027",
    linkedin: "https://www.linkedin.com/in/hrriday-ruparel-76b019276/",
    insta: "",
  },
  {
    name: "Nishi Shah",
    imgSrc: "./Images/nishi.webp",
    designation: "Sponsorship Coordinator",
    email: "nishi.shah@iitgn.ac.in",
    number: "+91 95123 00877",
    linkedin: "https://www.linkedin.com/in/nishi-shah-466a4424b/",
    insta: "https://instagram.com/nishishah2005/",
  },
  {
    name: "Darshan Zala",
    imgSrc: "./Images/darshan.webp",
    designation: "Conclave & Symposium Coordinator",
    email: "darshan.zala@iitgn.ac.in",
    number: "+91 93284 22062",
    linkedin: "https://www.linkedin.com/in/darshanzala/",
    insta: "https://www.instagram.com/darshanzala_/",
  },
  {
    name: "Dhruvi Sisodiya",
    imgSrc: "./Images/dhruvi.webp",
    designation: "Design Coordinator",
    email: "dhruvi.sisodiya@iitgn.ac.in",
    number: "+91 93289 21203",
    linkedin: "https://www.linkedin.com/in/dhruvi-sisodiya-1188ab251/",
    insta: "",
  },
  {
    name: "Aarav Shah",
    imgSrc: "./Images/aarav.webp",
    designation: "Tech Expo Coordinator",
    email: "aarav.shah@iitgn.ac.in",
    number: "+91 74878 47017",
    linkedin: "https://www.linkedin.com/in/aarav-shah-475122259/",
    insta: "https://www.instagram.com/aarav_200408/",
  },
  {
    name: "Aaditya Roy",
    imgSrc: "./Images/aaditya.webp",
    designation: "Marketing Coordinator",
    email: "aaditya.roy@iitgn.ac.in",
    number: "+91 96879 11316",
    linkedin: "https://www.linkedin.com/in/aaditya-roy-279485233/",
    insta: "https://www.instagram.com/deroygatory/",
  },
  {
    name: "Kavya Gotecha",
    imgSrc: "./Images/kavya.webp",
    designation: "Marketing Coordinator",
    email: "kavya.gotecha@iitgn.ac.in",
    number: "+91 90999 04181",
    linkedin: "https://www.linkedin.com/in/kavya-gotecha-4a7a98250/",
    insta: "https://www.instagram.com/kavyayaya_30/",
  },
];

export const TechTeamData = [
  {
    name: "Parth Govale",
    imgSrc: "./Images/parth.webp",
    designation: "Web Developer",
    email: "parth.govale@iitgn.ac.in",
    number: "+91 96198 69044",
    linkedin: "https://www.linkedin.com/in/parth-govale-a65120277/",
    insta: "https://instagram.com/parth_govale",
    github: "https://github.com/Demolus13"
  },
  {
    name: "Vraj Shah",
    imgSrc: "./Images/vraj.webp",
    designation: "Web Developer",
    email: "vraj.shah@iitgn.ac.in",
    number: "+91 94846 38348",
    linkedin: "https://www.linkedin.com/in/vraj-shah-6a634a254/",
    insta: "https://www.instagram.com/vraj_shah_2811/",
    github: "https://github.com/Vraj2811"
  },
  {
    name: "Dipesh Patidar",
    imgSrc: "./Images/dipesh.webp",
    designation: "Web Developer",
    email: "dipesh.patidar@iitgn.ac.in",
    number: "+91 62649 38418",
    linkedin: "https://www.linkedin.com/in/dipesh-patidar-586133256/",
    insta: "https://www.instagram.com/_dipesh_patidar_",
    github: "https://github.com/dipesh6264",
  },
  {
    name: "Pranav Joshi",
    imgSrc: "./Images/pranav.webp",
    designation: "Web Developer",
    email: "pranav.joshi@iitgn.ac.in",
    number: "+91 86989 55551",
    linkedin: "",
    insta: "https://instagram.com/ik_sm_stuff",
    github: "https://github.com/PJ29072004",
  },
  {
    name: "Romit Mohane",
    imgSrc:
      "https://media.licdn.com/dms/image/D4D03AQEcT-uVrr0xRw/profile-displayphoto-shrink_800_800/0/1695235027134?e=1701302400&v=beta&t=IxXPN2YQlRVv4c3-2QCBcDSJLkz9viYjj_yWtDtHTpM",
    designation: "Web Developer",
    email: "23110279@iitgn.ac.in",
    number: "+91 84258 05555",
    linkedin: "https://www.linkedin.com/in/romit-mohane/",
    insta: "https://instagram.com/its_romit.m",
    github: "https://github.com/Reckadon",
  },
  {
    name: "Vivek Raj",
    imgSrc: "./Images/vivek.webp",
    designation: "Web Developer",
    email: "23110362@iitgn.ac.in",
    number: "+91 70120 60919",
    linkedin: "https://www.linkedin.com/in/vivek-raj-1080aa28a/",
    insta: "https://instagram.com/ice71594",
    github: "https://github.com/VivekRaj2005",
  },
  {
    name: "Jaidev Khalane",
    imgSrc: "./Images/jaidev.webp",
    designation: "Web Developer",
    email: "jaidev.khalane@iitgn.ac.in",
    number: "+91 95156 30148",
    linkedin: "https://www.linkedin.com/in/jaidev-khalane-39504b286/",
    insta: "",
    github: "https://github.com/JaidevSK",
  },
  {
    name: "Kishan Ved",
    imgSrc: "./Images/kishan.webp",
    designation: "Web Developer",
    email: "kishan.ved@iitgn.ac.in",
    number: "+91 96193 19866",
    linkedin: "https://www.linkedin.com/in/kishan-ved-506140259/",
    insta: "https://instagram.com/kishan_.ved",
    github: "https://github.com/Kishan-Ved",
  },
];
