export const events = [
  {
    name: "Drone Racing Amalthea",
    description:
    "Introducing \"Drone Racing Amalthea\" (DRA) - an electrifying night drone racing event filled with vibrant neon colors and adrenaline-pumping action. Get ready to pilot your drone through a thrilling obstacle course, showcasing your agility and precision.\nAt DRA, we're offering an enticing cash prize pool of INR 2,00,000 for the victorious drone racer who conquers the challenge. This event promises an unforgettable experience that combines the thrill of racing with the mesmerizing spectacle of neon lights.\nJoin us for an evening of high-speed excitement and the chance to claim your share of the INR 2,00,000 prize pool at Drone Racing Amalthea!",
    imgSrc: "./Images/DRA.webp",
    accentColor: "#241f1f",
    textColor: "#FFF8FF",
    weight: "600",
    spanBg: "#00000065",
    cardBg: "#241f1f",
    contents: [],
    rulebookPath: "./RuleBooks/IDRL RuleBook.pdf",
    registerPath: "https://amalthea.iitgn.ac.in/register/dra"
  },
  {
    name: "Robowars",
    description:
      "Prepare for an ultimate experience in the ultimate battle of damage, strength, and technology. Witness the head-to-head clash of engineering beasts with metals and electric circuits. To all robo-maniacs, Amalthea 23 presents the most-awaited RoboWars for the first time at IIT Gandhinagar, where all the participants are about Savage Arena, Warrior; Victory is not granted but earned.\nThrilling Robot Combating Event \nIn a fierce battle for supremacy.\nThe robots will face off in an enclosed arena with various obstacles and hazards.",
    imgSrc: "./Images/Robowars.webp",
    contentTitle: "Robowar Video Release",
    contents: [
      {
        src: "Videos/Robowars-video-1.mp4",
        desc: "Robowars 1",
      },
      {
        src: "Videos/Robowars-video-2.mp4",
        desc: "Robowars 2"
      },
    ],
    accentColor: "#e37430",
    textColor: "#fff",
    weight: "600",
    spanBg: "#00000025",
    cardBg: "#20201e",
    rulebookPath: "./RuleBooks/RoboWars RuleBook.pdf",
    registerPath: "https://amalthea.iitgn.ac.in/register/robowars"
  },
  {
    name: "BrainWiz",
    description:
      "Welcome to BrainWiz, an exciting scientific journey designed exclusively for students in classes 9 to 12! This competition is not just about testing your logical and analytical abilities; it's an opportunity to unlock the doors to the world of engineering and gain real insights. Join us, and you'll have the incredible chance to engage with esteemed professors and accomplished alumni from IIT-GN, true experts in their respective fields. Get ready to embark on a thrilling adventure of knowledge and innovation",
    imgSrc: "./Images/Brainwiz.webp",
    accentColor: "#213555",
    textColor: "#213555",
    weight: "600",
    spanBg: "rgba(255, 255, 255, 0.50)",
    cardBg: "#F5EFE7",
    contents: [],
    rulebookPath: "https://docs.google.com/forms/d/e/1FAIpQLSd_uVqPLwmpNlStQYZ2KAEtxoGaG1wRVGHReJBTSfZSwrh3rw/viewform?usp=sf_link",
    registerPath: "https://docs.google.com/forms/d/e/1FAIpQLSdcIyHMHC3TsEnc_WW_sNrPPkAGGn6c9xVX91XyBadk67S7Vw/viewform?usp=sf_link"
  },
  {
    name: "Youth Tech-Tic",
    description:
      "Have you ever pondered the practical application of your academic knowledge in addressing real-world challenges? Worry not, because Youth Tech-tic, brought to you by Amalthea, is here to enlighten you on the intricate thought processes required to craft viable solutions. The event is very well designed for students in classes 9 to 12 to unleash their creativity and knowledge.\nThis event transcends mere competition. It offers you the opportunity not only to vie for prizes but also to connect with distinguished professionals, including professors, alumni, and experts in various domains right here at IITGN.\nPrepare to embark on a captivating journey into the realms of learning and innovation!",
    imgSrc: "./Images/YTT.webp",
    accentColor: "#2B4A59",
    textColor: "#2B4A59",
    weight: "600",
    spanBg: "#f1faffbf25",
    cardBg: "#C5CFD4",
    contents: [],
    rulebookPath: "./RuleBooks/Youth Tech-Tic RuleBook.pdf",
    registerPath: "https://amalthea.iitgn.ac.in/register/youth tech-tic"
  },
  {
    name: "GameJam",
    description:
    "Ever wondered what it takes to bring a video game to life? Curious about the thrill of designing and coding your own gaming masterpiece ? Look no further! Welcome to our Game Jam 2023- presented by Amalthea IIT Gandhinagar. With limited time and limitless potential, this game jam is your chance to create the next gaming sensation! So, dust off your gamepad, charge up your laptop, and prepare for an ultimate win and sheer gaming magic. We can't wait to see what epic adventures you'll conjure up! It’s an epic opportunity to expand your gaming network by connecting with your fellow gamers and learn the pro coding skills. So, gather your team and let's turn pixels into profit at the GameJam 2023.",
    imgSrc: "./Images/GameJam-bg.webp",
    accentColor: "#ec0062",
    textColor: "#FFF8FF",
    weight: "600",
    spanBg: "#19005165",
    cardBg: "#340053",
    contents: [],
    rulebookPath: "./RuleBooks/GameJam RuleBook.pdf",
    registerPath: "https://itch.io/jam/game-jam-2023-ad"
  },
  {
    name: "Drift Racing",
    description:
    "Get ready for the ultimate RC car racing experience at \"Drift,\" the highlight of IIT Gandhinagar's annual technical summit, Amalthea. Our event boasts the largest RC track in Gujarat, offering participants a thrilling and challenging racecourse. Prepare to showcase your precision, speed, and control as you navigate hairpin turns and nail-biting drifts. With a perfect blend of technical skill and adrenaline-pumping fun, \"Drift\" promises an unforgettable experience for all RC enthusiasts. Join us for this high-octane competition and prove your prowess on the track. Don't miss out on the action-packed excitement!",
    imgSrc: "./Images/Drift-Racing.webp",
    accentColor: "#e37430",
    textColor: "#FFF8FF",
    weight: "600",
    spanBg: "#00000065",
    cardBg: "#20201e",
    contents: [],
    rulebookPath: "./RuleBooks/Drift RuleBook.pdf",
    registerPath: "https://amalthea.iitgn.ac.in/register/drift-racing"
  },
  {
    name: "D'Code",
    description:
    "D'code is a competitive programming event hosted on CodeChef for people intrigued by programming  all across India. Hack into the world of competitive programming and test your grit in algorithms.\ndef Dcode():\n\u00A0\u00A0\u00A0\u00A0if (Want to participate):\n\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0print('Register here')\nIt provides you with the best competitive environment and offers opportunity for programming enthusiasts to enhance their analytical skills.",
    imgSrc: "./Images/Dcode.webp",
    accentColor: "#42e8e0",
    textColor: "#FFF8FF",
    weight: "600",
    spanBg: "#00000045",
    cardBg: "#180d5b",
    contents: [],
    rulebookPath: "./RuleBooks/Dcode RuleBook.pdf",
    registerPath: "https://amalthea.iitgn.ac.in/register/dcode"
  },
  {
    name: "StockRush",
    description:
    "Ready to Conquer the Market? Dive into the world of stock trading in this exhilarating event! Stockrush invites traders, investors, and financial enthusiasts worldwide to showcase their skills. With virtual currency and real-world strategies, seize the opportunity to dominate the NSE and BSE. Are you up for the challenge? \n Join us at Amalthea 23 for Stockrush - where trading prowess meets adrenaline!",
    imgSrc: "./Images/StockRush.webp",
    accentColor: "#e0aa0d",
    textColor: "#FFF8FF",
    weight: "600",
    spanBg: "#00000065",
    cardBg: "#20201e",
    contents: [],
    rulebookPath: "./RuleBooks/StockRush RuleBook.pdf",
    registerPath: "https://amalthea.iitgn.ac.in/register/stockrush"
  },
  // {
  //   name: "Innovation Nexus",
  //   description:
  //   "Ready to Conquer the Market? Dive into the world of stock trading in this exhilarating event! Stockrush invites traders, investors, and financial enthusiasts worldwide to showcase their skills. With virtual currency and real-world strategies, seize the opportunity to dominate the NSE and BSE. Are you up for the challenge? \n Join us at Amalthea 23 for Stockrush - where trading prowess meets adrenaline!",
  //   imgSrc: "./Images/IN.webp",
  //   accentColor: "#005d88",
  //   textColor: "#FFF8FF",
  //   weight: "600",
  //   spanBg: "#00000065",
  //   cardBg: "#000201",
  //   contents: [],
  //   rulebookPath: "./RuleBooks/StockRush RuleBook.pdf",
  //   registerPath: "https://amalthea.iitgn.ac.in/register/innovation-nexus"
  // },
  // {
  //   name: "coming soon",
  //   description: "bla bla bla",
  //   imgSrc: "./Images/coming_soon.webp",
  //   contents: [],
  // },
  // {
  //   name: "coming soon",
  //   description: "bla bla bla",
  //   imgSrc: "./Images/coming_soon.webp",
  //   contents: [],
  //   accentColor: "#000",
  // },
];
