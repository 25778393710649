/*Format:
  {
    imgSrc:"image link",
    name: "name",
    title: "title",
    topic: "topic of speach",
    achievements: "time of event",
  }
*/
export const conclaveData = [
  {
    imgSrc: "./Images/rinkesh.webp",
    name: "RINKESH BANSAL",
    title: "Program Dirctor and Master Inventor, IBM",
    achievement: " Inventor, Team Leader, Security Visionary. Expert in strategy, patents, and team building.",
    edu: "",
    web: "",
    lin: "https://www.linkedin.com/in/rinkesh-bansal-09798843/?originalSubdomain=in",
  },
  {
    imgSrc: "./Images/GeethaKannan.webp",
    name: "GEETHA KANNAN",
    title: "Founder and CEO of Wequity, Women for Technology",
    achievement: "Ex VP HR of Infosys Tech Ltd. and Ex HR of ANZ",
    edu: "B.Com and MBA graduate (gold medallist) from Bharathiar University",
    lin:"https://www.linkedin.com/in/geethakannanwequity/?originalSubdomain=in",
    web: "https://www.theleadershipsummit.org/2022/speakers/geetha-kannan/"
  },
  {
    imgSrc: "./Images/Aabhijeet.webp",
    name: "ABHIJEET SINHA",
    title: "Program Director - EASE of doing Buisness",
    achievement: "Project Director-NHEV | DIISHA | Drone Pilot, President-Charge Point Operators Society (CPOs) Of India",
    edu: "",
    web: "",
    lin:"https://www.linkedin.com/in/abhijeetsinhaa/?originalSubdomain=in",
  },
  {
    imgSrc: "./Images/kislay.webp",
    name: "KISLAY PANKAJ",
    title: "Co-founder and CEO of Lectrix Technologies",
    achievement: "Ex-CTO of Micelio-Electric Vehicles and Ex-Founding Member of Ather Technologies",
    lin:"https://linkedin.com/in/kislay-pankaj/?originalSubdomain=in",
    web: ""
  },
  {
    imgSrc: "./Images/rajagopalan.webp",
    name: "Dr. RAJAGOPALAN PANDEY",
    title: "Assistant Professor, NAMTECH",
    achievement: "NSCF International Young Scientist Award; Chaoyang Scholar Grant & Fellowship; Talent introduction fellow; Indo-Korea Research Fellow; Outstanding Researcher, Zhejiang University; Best Technology Development Award-2018, IIT Indore",
    edu: "IIT Indore",
    lin: "https://www.linkedin.com/in/rajagopalan-p-47934b65/?originalSubdomain=in",
    ins:"",
    web: "https://scholar.google.com/citations?user=ZhJsA0wAAAAJ&hl=en"
  },
  {
    imgSrc: "./Images/sucharu.webp",
    name: "SUCHARU UPPAL",
    title: "Joint President, Brand and Retail at Grasim Industries Limited",
    achievement: "WeQual Awards Winner, Asia Pacific 2023",
    lin:"https://www.linkedin.com/in/sucharu-uppal-340b5516/?originalSubdomain=in",
    edu: "",
    web: ""
  },
]