/*Format:
  {
    imgSrc:"image link",
    name: "name",
    title: "title",
    topic: "topic of speach",
    achievements: "time of event",
  }
*/
export const witData = [
    {
      imgSrc: "./Images/shefaligaur.webp",
      name: "SHEFALI GAUR",
      title: "Transformation Leader - Ecosystem Engineering, IBM India Software Labs",
      achievement: "Agile Servant Leader and D&I Champion, Agile Change Leader (OKRA Coach).",
      edu: "",
      lin:"https://www.linkedin.com/in/shefaligaur/?originalSubdomain=in",
      web: ""
    },
    {
      imgSrc: "./Images/Minu Sirsalewala.webp",
      name: "MINU SIRSALEWALA",
      title: "Executive Editor-Special Projects at Cyber Media India Limited",
      achievement: "Dataquest/PCQuest/Voice&Data",
      edu: "",
      lin: "",
      web: ""
    },
    {
      imgSrc: "./Images/oshi.webp",
      name: "OSHI KUMARI",
      title: "Co-founder at insideFPV ",
      achievement: "Consumer & Defence Drones | Shark Tank India Season 2 ",
      edu: "",
      lin:"https://www.linkedin.com/in/oshi-kumari/?originalSubdomain=in",
      web: ""
    },
    {
      imgSrc: "./Images/Deepa.webp",
      name: "DEEPA PARIKH",
      title: "Head of Solutions Engineering for India at Akamai Technologies",
      achievement: "SuperAchiever Winner at ITV Wonder Women 2023 awards and Pastspeaker at SheSparks 2023",
      web: "",
      lin:"https://www.linkedin.com/in/deepa-parikh-7883904/?originalSubdomain=in",
    },
    {
      imgSrc: "./Images/GeethaKannan.webp",
      name: "GEETHA KANNAN",
      title: "Founder and CEO of Wequity",
      achievement: "Founder and CEO of Wequity. Ex-VP HR of Infosys.",
      edu: "",
      lin:"https://www.linkedin.com/in/geethakannanwequity/?originalSubdomain=in",
      web: ""
    },{
      imgSrc: "./Images/beena.webp",
      name: "BEENA KOTHADIA",
      title: "Executive Plant Management - General Electric",
      achievement: "India's Top 25 Women Ahead (ET) Award Winner | India's Top 10 Women Role Model Award Winner.",
      edu: "",
      lin:"https://www.linkedin.com/in/beena-kothadia/?originalSubdomain=in",
      web: ""
    },
  
  ]
